<!-- eslint-disable vue/no-parsing-error -->
<template>
  <IAmOverlay :loading="loading || loadingUpload">
    <section>
      <validation-observer
        #default="{ handleSubmit, invalid, pristine }"
        ref="refFormObserver"
      >
        <!-- SECTION Form -->
        <b-form @submit.prevent="handleSubmit(nextHandle)">
          <!-- SECTION Card agency basic info -->
          <b-card
            header-tag="header"
            border-variant="info"
            header-bg-variant="light-info"
            header-class="py-1"
            class="border mt-1"
          >
            <!-- ANCHOR header -->
            <template #header>
              <h6 class="m-0">
                {{ $t('agency.agencyInfo') }}
              </h6>
            </template>
            <b-card-body class="p-0">
              <b-row class="mt-1">
                <!-- ANCHOR Agency Owner -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Agency Owner"
                    rules="required|max:150"
                  >
                    <b-form-group label-for="agencyOwner">
                      <template #label>
                        {{ $t('agency.labelOwner') }}
                        <span class="text-danger">(*)</span>
                      </template>
                      <b-form-input
                        id="agencyOwner"
                        v-model="agencyDataToAdd.agencyOwner.name"
                        :state="getValidationState(validationContext) === false ? false : null"
                        maxlength="150"
                        lazy-formatter
                        :formatter="trimInput"
                        :placeholder="$t('agency.placeholderOwner')"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR Email Address: required, email, max 150 -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('agency.labelEmail')"
                    rules="required|email|max:150"
                  >
                    <!-- Bỏ unique: |isUnique:agencies,email -->
                    <b-form-group label-for="emailAddress">
                      <template #label>
                        {{ $t('agency.labelEmail') }}
                        <span class="text-danger">(*)</span>
                      </template>
                      <b-form-input
                        id="emailAddress"
                        v-model="agencyDataToAdd.emailAddress"
                        :state="getValidationState(validationContext) === false ? false : null"
                        maxlength="150"
                        lazy-formatter
                        :formatter="trimInput"
                        :placeholder="$t('agency.placeholderEmail')"
                        :value="agencyDataToAdd.emailAddress"
                        debounce="300"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR Agency Owner Phone: phoneNumber-->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('agency.labelOwnerPhone')"
                    rules="required|phoneNumber"
                  >
                    <!-- |phoneNumber|isUnique:agencies,phonenumber -->
                    <b-form-group label-for="ownerPhone">
                      <template #label>
                        {{ $t('agency.labelOwnerPhone') }}
                        <span class="text-danger">(*)</span>
                      </template>
                      <b-form-input
                        id="ownerPhone"
                        v-model="agencyDataToAdd.agencyOwner.phoneNumber"
                        :state="getValidationState(validationContext) === false ? false : null"
                        lazy-formatter
                        :formatter="trimInput"
                        :debounce="300"
                        :placeholder="$t('agency.placeholderOwnerPhone')"
                      />
                      <!-- maxlength="10" -->
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR Date Of Birth -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('agency.birthday')"
                    rules=""
                  >
                    <b-form-group label-for="birthday">
                      <template #label>
                        {{ $t('agency.birthday') }}
                        <!-- <span class="text-danger">(*)</span> -->
                      </template>
                      <flat-pickr
                        v-model="agencyDataToAdd.birthDayOwner"
                        class="d-none"
                      />
                      <flat-pickr
                        id="birthDay"
                        v-model="agencyDataToAdd.birthDayOwner"
                        name="birthday"
                        class="form-control"
                        :placeholder="$t('customer.placeholderDoB')"
                        :state="getValidationState(validationContext) === false ? false : null"

                        :config="{
                          allowInput: true,
                          locale: locale === 'vi' ? Vietnamese : null,
                          dateFormat: 'Y-m-d',
                          altFormat: 'd-m-Y',
                          altInput: true,
                          disableMobile: true,
                          maxDate: new Date(),
                          minDate:new Date(1920, 0, 1)
                        }"
                      />
                      <feather-icon
                        icon="CalendarIcon"
                        size="20"
                        style="
                            position: absolute;
                            top: 55%;
                            right: 20px;
                            transform: translateY(-50%);
                            cursor: pointer;
                           color: #aaa;
                            "
                      />
                    </b-form-group>
                    <b-form-invalid-feedback :state="getValidationState(validationContext) === false ? false : null">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR Identity Card Number -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('agency.labelIdentityCardNum')"
                    rules="max:15"
                  >
                    <b-form-group label-for="identityCardNumber">
                      <template #label>
                        {{ $t('agency.labelIdentityCardNum') }}
                        <!-- <span class="text-danger">(*)</span> -->
                      </template>
                      <b-form-input
                        id="identityCardNumber"
                        v-model="agencyDataToAdd.identityCardNumber"
                        v-remove-non-numeric-chars.allNumber
                        :state="getValidationState(validationContext) === false ? false : null"
                        lazy-formatter
                        :formatter="trimInput"
                        :placeholder="$t('agency.placeholderIdentityCardNum')"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR Identity Card Name -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('agency.labelIdentityCardName') "
                    rules=""
                  >
                    <b-form-group label-for="identityCardName">
                      <template #label>
                        {{ $t('agency.labelIdentityCardName') }}
                        <!-- <span class="text-danger">(*)</span> -->
                      </template>
                      <b-form-input
                        id="identityCardName"
                        v-model="agencyDataToAdd.identityCardName"
                        :state="getValidationState(validationContext) === false ? false : null"
                        lazy-formatter
                        :formatter="trimInput"
                        maxlength="255"
                        :placeholder="$t('agency.placeholderIdentityCardName')"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR Place Of Issue -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('agency.labelPlaceOfIssue')"
                    rules=""
                  >
                    <b-form-group label-for="placeOfIssue">
                      <template #label>
                        {{ $t('agency.labelPlaceOfIssue') }}
                        <!-- <span class="text-danger">(*)</span> -->
                      </template>
                      <b-form-input
                        id="placeOfIssue"
                        v-model="agencyDataToAdd.placeOfIssue"
                        :state="getValidationState(validationContext) === false ? false : null"
                        lazy-formatter
                        :formatter="trimInput"
                        maxlength="255"
                        :placeholder="$t('agency.placeholderPlaceOfIssue')"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col cols="12">
                  <b-row>
                    <!-- ANCHOR Identity card front image -->
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <validation-provider
                        #default="validationContext"
                        ref="refProviderIDFrontImageFile"
                        :name="$t('agency.uploadFrontId')"
                      >
                        <b-form-group
                          class="font-weight-bold"
                          label-for="frontIdFrontImage"
                        >
                          <template #label>
                            {{ $t('agency.uploadFrontId') }}
                          </template>
                          <div class="input-group custom-file-button">
                            <label
                              class="input-group-text"
                              for="frontIdFrontImage"
                            >
                              {{ $t('agency.uploadImage') }}</label>
                            <b-form-file
                              id="frontIdFrontImage"
                              ref="refInputFrontIDPhoto"
                              v-model="agencyFile.idFrontImage"
                              accept=".jpg, .png"
                              class="form-control"
                              plain
                              placeholder="Chưa chọn ảnh"
                              :state="getValidationState(validationContext) === false ? false : null"
                              @input="inputImageIDCardFrontFormData"
                            />
                          </div>
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                    <!-- ANCHOR Identity card back image -->
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <validation-provider
                        #default="validationContext"
                        ref="refProviderIDBackImageFile"
                        :name="$t('agency.uploadBackId')"
                      >
                        <b-form-group
                          class="font-weight-bold"
                          label-for="frontIdBackImage"
                        >
                          <template #label>
                            {{ $t('agency.uploadBackId') }}
                          </template>
                          <div class="input-group custom-file-button">
                            <label
                              class="input-group-text"
                              for="frontIdBackImage"
                            >
                              {{ $t('agency.uploadImage') }}</label>
                            <b-form-file
                              id="frontIdBackImage"
                              ref="refInputBackIDPhoto"
                              v-model="agencyFile.idBackImage"
                              accept=".jpg, .png"
                              class="form-control"
                              plain
                              placeholder="Chưa chọn ảnh"
                              :state="getValidationState(validationContext) === false ? false : null"
                              @input="inputImageIDCardBackFormData"
                            />
                          </div>
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                    <!-- ANCHOR ID front image -->
                    <b-col
                      cols="5"
                      md="6"
                      centered
                    >
                      <b-img
                        v-if="agencyDataToAdd.identityCardFrontImage || previewIdentityCardFrontImage"
                        :src="agencyDataToAdd.identityCardFrontImage || previewIdentityCardFrontImage"
                        fluid
                        alt="id card"
                        style="max-height: 200px"
                        class="border shadow-lg mb-1"
                      />
                    </b-col>

                    <!-- ANCHOR Id back image -->
                    <b-col
                      cols="5"
                      md="6"
                      centered
                    >
                      <b-img
                        v-if="agencyDataToAdd.identityCardBackImage || previewIdentityCardBackImage"
                        :src="agencyDataToAdd.identityCardBackImage || previewIdentityCardBackImage"
                        fluid
                        alt="id card"
                        style="max-height: 200px"
                        class="border shadow-lg mb-1"
                      />
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
          <!-- !SECTION -->

          <!-- SECTION Agency business information -->
          <b-card
            header-tag="header"
            border-variant="info"
            header-bg-variant="light-info"
            header-class="py-1"
            class="border mt-1"
          >
            <!-- ANCHOR Header -->
            <template #header>
              <h6 class="m-0">
                {{ $t('agency.agencyBusinessInfo') }}
              </h6>
            </template>
            <b-card-body class="p-0">
              <b-row class="mt-1">
                <!-- ANCHOR company name -->
                <b-col
                  cols="12"
                  sm="6"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('agency.agencyCompanyName')"
                    rules="required"
                  >
                    <b-form-group label-for="agency-name">
                      <template #label>
                        {{ $t('agency.agencyCompanyName') }}
                        <span class="text-danger">(*)</span>
                      </template>
                      <b-form-input
                        id="agency-name"
                        v-model="agencyDataToAdd.agencyName"
                        :name="$t('agency.agencyCompanyName')"
                        :placeholder="`${$t('agency.enter')} ${$t('agency.agencyCompanyName')}`"
                        :state="getValidationState(validationContext) === false ? false : null"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR Tax Code -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Tax Code"
                    rules=""
                  >
                    <b-form-group label-for="tax-code">
                      <template #label>
                        {{ $t('agency.labelTaxCode') }}
                        <!-- <span class="text-danger">(*)</span> -->
                      </template>
                      <b-form-input
                        id="tax-code"
                        v-model="agencyDataToAdd.taxCode"
                        v-remove-non-numeric-chars.allNumber
                        :state="getValidationState(validationContext) === false ? false : null"
                        lazy-formatter
                        :formatter="trimInput"
                        :placeholder="$t('agency.placeholderTaxCode')"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR Send Monthly Report -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('agency.labelMonthlyReport')"
                    rules=""
                  >
                    <b-form-group
                      label-for="enableSendMonthlyReport"
                      :state="getValidationState(validationContext) === false ? false : null"
                    >
                      <template #label>
                        {{ $t('agency.labelMonthlyReport') }}
                        <!-- <span class="text-danger">(*)</span> -->
                      </template>
                      <v-select
                        v-model="agencyDataToAdd.enableSendMonthlyReport"
                        :options="[
                          {label: 'Yes', value: true },
                          {label: 'No', value: false}
                        ]"
                        :reduce="(val) => val.value"
                        :clearable="false"
                        input-id="enableSendMonthlyReport"
                        :placeholder="$t('agency.placeholderMonthlyReport')"
                      >
                        <template #option="data">
                          <span>
                            {{ $t(data.label) }}
                          </span>
                        </template>
                        <template #selected-option="data">
                          <span>
                            {{ $t(data.label) }}
                          </span>
                        </template>
                        <template #no-options>
                          {{ $t('noOptions') }}
                        </template>
                      </v-select>
                      <b-form-invalid-feedback :state="getValidationState(validationContext) === false ? false : null">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR Agency Code -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('agency.agencyCode')"
                    :rules="`min:${isRoleF1 ? '2' : '4'}|userName|isUnique:agencies,agencyCode`"
                  >
                    <b-form-group label-for="agencyCode">
                      <template #label>
                        {{ $t('agency.agencyCode') }}
                      </template>
                      <b-form-input
                        id="agencyCode"
                        v-model="agencyDataToAdd.agencyCode"
                        :state="getValidationState(validationContext) === false ? false : null"
                        maxlength="16"
                        :disabled="isRoleF2"
                        :debounce="300"
                        :formatter="upperCaseFormatter"
                        :placeholder="`${$t('agency.enter')} ${$t('agency.agencyCode')}`"
                      />

                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR company address -->
                <b-col
                  cols="12"
                  sm="6"
                >
                  <b-form-group
                    label-for="agency-address"
                    :label="$t('myAccount.addressCompany')"
                  >
                    <b-form-input
                      id="agency-address"
                      v-model="agencyDataToAdd.agencyAddress"
                      :name="$t('myAccount.addressCompany')"
                      :placeholder="`${$t('myAccount.enter')} ${$t('myAccount.addressCompany')}`"
                    />
                  </b-form-group>
                </b-col>

                <!-- ANCHOR Founding Date -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('agency.agencyFoundingDate')"
                  >
                    <b-form-group
                      :label="`${$t('agency.agencyFoundingDate')}`"
                      label-for="agencyFoundingDate"
                    >
                      <flat-pickr
                        v-model="agencyDataToAdd.agencyFoundingDate"
                        class="d-none"
                      />
                      <flat-pickr
                        v-model="agencyDataToAdd.agencyFoundingDate"
                        class="form-control"
                        :placeholder="$t('agency.agencyFoundingDate')"
                        :state="getValidationState(validationContext) === false ? false : null"
                        :config="{
                          allowInput: true,
                          locale: locale === 'vi' ? Vietnamese : null,
                          dateFormat: 'Y-m-d',
                          altFormat: 'd-m-Y',
                          altInput: true,
                          disableMobile: true,
                          minDate:'1920-01-01',
                          maxDate:new Date(),
                        }"
                      />
                      <feather-icon
                        icon="CalendarIcon"
                        size="20"
                        style="
                            position: absolute;
                            top: 55%;
                            right: 20px;
                            transform: translateY(-50%);
                            cursor: pointer;
                           color: #aaa;
                            "
                      />
                    </b-form-group>
                    <b-form-invalid-feedback
                      :state="
                        getValidationState(validationContext) === false
                          ? false
                          : null
                      "
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR warningCredit  -->
                <!-- <b-col
                  cols="12"
                  sm="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Agency Warning Credit"
                    :rules="isRoleF2 ? `integer|agencyWarningCredit:${agencyDataToAdd.limitCredit}|agencyMaximumCredit:${limitF2}` : `integer|agencyWarningCredit:${agencyDataToAdd.limitCredit}`"
                  >
                    <b-form-group
                      label-for="agency-warning-credit"
                    >
                      <template #label>
                        <div>
                          <span>{{ $t('agency.labelWarningCredit') }}</span>
                          <p
                            v-if="isRoleF2"
                            class="d-inline mb-0"
                          >
                            (<span class="text-danger">Tối đa: {{ `< ${formatCurrency(limitF2)}` }}</span>)
                          </p>
                        </div>
                      </template>

                      <b-form-input
                        id="agency-warning-credit"
                        v-model="agencyDataToAdd.warningCredit"
                        :state="getValidationState(validationContext) === false ? false : null"
                        type="number"
                        :placeholder="`${$t('myAccount.enter')} ${$t('agency.labelWarningCredit')}`"
                        @click="selectInputText"
                      />
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext) === false ? false : null"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col> -->

                <!-- ANCHOR limitCredit -->
                <b-col
                  cols="12"
                  sm="6"
                >
                  <!-- :rules="`integer|agencyLimitCredit:${agencyDataToAdd.warningCredit}`" -->
                  <validation-provider
                    #default="validationContext"
                    name="Agency Limit Credit"
                    :rules="isRoleF2 && isLimitF3BalanceAccordingF2Balance ? `integer|agencyLimitCredit:${agencyDataToAdd.warningCredit}|agencyMaximumCredit:${limitF2}` : `integer|agencyLimitCredit:${agencyDataToAdd.warningCredit}`"
                  >
                    <b-form-group label-for="agency-limit-credit">
                      <template #label>
                        <div>
                          <span>{{ $t('agency.labelLimitCredit') }}</span>
                          <p
                            v-if="isRoleF2 && isLimitF3BalanceAccordingF2Balance"
                            class="d-inline mb-0"
                          >
                            (<span class="text-danger">Tối đa: {{ `< ${formatCurrency(limitF2)}` }}</span>)
                          </p>
                        </div>
                      </template>
                      <b-form-input
                        id="agency-limit-credit"
                        v-model="agencyDataToAdd.limitCredit"
                        :state="getValidationState(validationContext) === false ? false : null"
                        type="number"
                        :placeholder="`${$t('myAccount.enter')} ${$t('agency.labelLimitCredit')}`"
                        @click="selectInputText"
                      />
                      <b-form-invalid-feedback :state=" getValidationState(validationContext) === false ? false : null">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR Payment method -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('agency.paymentMethod')"
                    rules="required"
                  >
                    <b-form-group
                      label-for="paymentMethod"
                      :state="getValidationState(validationContext) === false ? false : null"
                    >
                      <template #label>
                        {{ $t('agency.paymentMethod') }}
                        <span class="text-danger">(*)</span>
                      </template>
                      <v-select
                        v-model="agencyDataToAdd.paymentMethod"
                        :options="[
                          { label: 'overdraft', value: 'OVERDRAFT' },
                          { label: 'prepay', value: 'PREPAY' },
                        ]"
                        :reduce="(val) => val.value"
                        :clearable="false"
                        input-id="paymentMethod"
                        :placeholder="$t('agency.paymentMethod')"
                      >
                        <template #option="data">
                          <span>
                            {{ $t(`agency.${data.label}`) }}
                          </span>
                        </template>

                        <template #selected-option="data">
                          <span>
                            {{ $t(`agency.${data.label}`) }}
                          </span>
                        </template>

                        <template #no-options>
                          {{ $t('noOptions') }}
                        </template>
                      </v-select>
                      <b-form-invalid-feedback :state="getValidationState(validationContext) === false ? false : null">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Document contract -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    ref="documentContractFile"
                    :name="`${$t('agency.documentContract')}` "
                  >
                    <b-form-group
                      class="font-weight-bold"
                      :label="$t('agency.documentContract')"
                      label-for="documentContractUrlFile"
                    >
                      <div v-if="agencyDataToAdd.documentContractUrl.length">
                        <div
                          v-for="(docContractFile, docContractFileIndex) in agencyDataToAdd.documentContractUrl"
                          :key="docContractFileIndex"
                        >
                          <em>{{ docContractFileIndex + 1 }}.</em>
                          <a
                            :href="docContractFile"
                            alt="download file"
                            target="_blank"
                            class="hover-underline"
                          >
                            {{ shortenFileName(docContractFile) }}
                          </a>
                          <b-button
                            variant="flat-danger"
                            class="p-50 rounded-circle"
                            @click="deleteDocumentContract(docContractFileIndex)"
                          >
                            <IAmIcon icon="closeOutline" />
                          </b-button>
                        </div>
                      </div>
                      <span
                        v-else
                        class="text-secondary"
                      >
                        Chưa có file
                      </span>
                      <div class="mt-25">
                        <!-- <label
                          for="documentContractUrlFile"
                          class="bg-info p-50 rounded text-white cursor-pointer hover-label"
                        >
                          <div class="d-flex-center gap-1">
                            <IAmIcon
                              icon="cloudUploadOutline"
                              size="18"
                              color="white"
                            />
                            {{ $t('agency.uploadContract') }}
                          </div>
                        </label> -->
                        <div class="input-group custom-file-button">
                          <label
                            class="input-group-text"
                            for="documentContractUrlFile"
                          >
                            {{ $t('agency.uploadContract') }}</label>
                          <b-form-file
                            id="documentContractUrlFile"
                            ref="refInputDocumentContractUrl"
                            v-model="agencyFile.documentContract"
                            :accept="fileCanUpload"
                            class="form-control"
                            plain
                            multiple
                            :state="getValidationState(validationContext) === false ? false : null"
                            @input="handleUploadContractFiles"
                          />
                        </div>
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </div>
                      <small class="font-italic text-secondary">Hệ thống chỉ hỗ trợ tải file {{ fileCanUpload }} có dung lượng nhỏ hơn {{ sizeFormatter(MAX_SIZE_TO_UPLOAD_FILE) }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
          <!-- !SECTION -->

          <!-- SECTION STATUS -->
          <b-card
            header-tag="header"
            border-variant="info"
            header-bg-variant="light-info"
            header-class="py-1"
            class="border my-1"
          >
            <!-- ANCHOR header -->
            <template #header>
              <h6 class="m-0">
                {{ $t('agency.labelStatus') }}
              </h6>
            </template>
            <b-card-body class="p-0">
              <b-row class="mt-1">
                <!-- ANCHOR Status -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('agency.labelStatus')"
                    rules="required"
                  >
                    <b-form-group
                      label-for="status"
                      :state="getValidationState(validationContext) === false ? false : null"
                    >
                      <template #label>
                        {{ $t('agency.labelStatus') }}
                        <span class="text-danger">(*)</span>
                      </template>
                      <v-select
                        v-model="agencyDataToAdd.status"
                        :options="statusAgencyOptions"
                        :reduce="(val) => val.value"
                        :clearable="false"
                        input-id="status"
                        :placeholder="$t('agency.placeholderStatus')"
                      >
                        <template #option="data">
                          <span>
                            {{ $t(data.label) }}
                          </span>
                        </template>
                        <template #selected-option="data">
                          <span>
                            {{ $t(data.label) }}
                          </span>
                        </template>
                        <template #no-options>
                          {{ $t('noOptions') }}
                        </template>
                      </v-select>
                      <b-form-invalid-feedback :state="getValidationState(validationContext) === false ? false : null">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
          <!-- !SECTION -->

          <!-- SECTION Air Account for Agency -->
          <b-card
            header-tag="header"
            border-variant="info"
            header-bg-variant="light-info"
            header-class="py-1"
            class="border my-1"
          >
            <!-- ANCHOR header -->
            <template #header>
              <h6 class="m-0">
                {{ $t('agency.airAccountForAgencyText') }}
              </h6>
            </template>
            <!-- Form Input -->
            <b-card-body class="p-0">
              <h5 class="my-2">
                {{ $t('agency.provideAccount') }}
              </h5>
              <b-row class="mt-1">
                <!-- ANCHOR Air account Username -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('agency.labelAirUsername')"
                    rules=""
                  >
                    <b-form-group label-for="airAccountUsername">
                      <template #label>
                        {{ $t('agency.labelAirUsername') }}
                        <!-- <span class="text-danger">(*)</span> -->
                      </template>
                      <!-- {label: 'Account 1', value: 'account1' },
                      {label: 'Account 2', value: 'account2'},
                      {label: 'Account 3', value: 'account3'} -->
                      <v-select
                        id="airAccountUsername"
                        v-model="agencyDataToAdd.airAccount.username"
                        :options="[
                        ]"
                        :reduce="(val) => val.value"
                        clearable
                        input-id="airAccountUsername"
                        :placeholder="$t('agency.placeholderAirUsername')"
                      >
                        <template #option="data">
                          <span>
                            {{ $t(data.label) }}
                          </span>
                        </template>

                        <template #selected-option="data">
                          <span>
                            {{ $t(data.label) }}
                          </span>
                        </template>

                        <template #no-options>
                          {{ $t('noOptions') }}
                        </template>
                      </v-select>
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- ANCHOR Airline -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    :name="$t('agency.labelAirline')"
                    rules=""
                  >
                    <b-form-group
                      label-for="airline"
                      :state="getValidationState(validationContext) === false ? false : null"
                    >
                      <template #label>
                        {{ $t('agency.labelAirline') }}
                        <!-- <span class="text-danger">(*)</span> -->
                      </template>
                      <v-select
                        v-model="agencyDataToAdd.airline"
                        :options="hotAirlineOptions"
                        :reduce="(val) => val.value"
                        :clearable="false"
                        input-id="airline"
                        :placeholder="$t('agency.placeholderAirline')"
                      >
                        <template #option="data">
                          <span>
                            {{ $t(data.label) }}
                          </span>
                        </template>
                        <template #selected-option="data">
                          <span>
                            {{ $t(data.label) }}
                          </span>
                        </template>
                        <template #no-options>
                          {{ $t('noOptions') }}
                        </template>
                      </v-select>
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
          <!-- !SECTION -->

          <b-card
            header-tag="header"
            border-variant="info"
            header-bg-variant="light-info"
            header-class="py-1"
            class="border my-1"
          >
            <template #header>
              <h6 class="m-0">
                Cài đặt
              </h6>
            </template>
            <b-card-body class="p-0">
              <b-row class="mt-1">
                <b-col>
                  <b-form-group :label="'Quyền được đặt vé'">
                    <b-form-checkbox
                      v-for="(bLimit) in bookingLimit"
                      :key="bLimit.airlineSource"
                      class="custom-control-success"
                      name="check-button"
                      inline
                      :disabled="!canChangeIssueBookingLimit"
                      :checked="bLimit.enableBooking"
                      @change="(checked) => changeBookingLimit(checked, bLimit.airlineSource)"
                    >
                      {{ $te(`flight.sourceName.${bLimit?.airlineSource.toUpperCase()}`)
                        ? $t(`flight.sourceName.${bLimit?.airlineSource.toUpperCase()}`)
                        : bLimit?.airlineSource.toUpperCase() }}
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mt-1">
                <b-col>
                  <b-form-group :label="'Quyền được xuất vé'">
                    <h6
                      v-if="canChangeIssueBookingLimit"
                      class="text-warning"
                    >
                      Bạn đang chọn cho {{ childFloor }} tự xuất vé
                    </h6>
                    <b-form-checkbox
                      v-for="(iLimit) in issueLimit"
                      :key="iLimit.airlineSource"
                      class="custom-control-success"
                      name="check-button"
                      inline
                      :disabled="!canChangeIssueBookingLimit"
                      :checked="iLimit.allowance"
                      @change="(checked) => changeIssueLimit(checked, iLimit.airlineSource)"
                    >
                      {{ $te(`flight.sourceName.${iLimit?.airlineSource.toUpperCase()}`)
                        ? $t(`flight.sourceName.${iLimit?.airlineSource.toUpperCase()}`)
                        : iLimit?.airlineSource.toUpperCase() }}
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>

          <!-- SECTION SOCIAL ACCOUNT -->
          <b-card
            header-tag="header"
            border-variant="info"
            header-bg-variant="light-info"
            header-class="py-1"
            body-class="pb-1 pt-50"
            class="border my-1"
          >
            <template #header>
              <h6 class="m-0">
                Mạng xã hội
              </h6>
            </template>

            <b-card-body class="p-0">
              <div v-if="agencyDataToAdd.socialAccounts.length > 0">
                <b-row
                  v-for="(item, index) in agencyDataToAdd.socialAccounts"
                  :key="index"
                  class="mx-0"
                  no-gutters
                >
                  <!-- ANCHOR - Tên mạng xã hội -->
                  <b-col
                    cols="12"
                    md="4"
                    class="pr-md-1"
                  >
                    <!-- <validation-provider
                      #default="validationContext"
                      name="Tên mạng xã hội"
                      rules="required"
                    > -->
                    <b-form-group :label="`Mạng xã hội #${index + 1}`">
                      <!-- :state="getValidationState(validationContext) === false ? false : null" -->
                      <b-form-input
                        :id="`number-${index}`"
                        v-model="item.type"
                        class="font-weight-bold"
                        placeholder="Nhập vào"
                      />
                    </b-form-group>
                    <!-- </validation-provider> -->
                  </b-col>

                  <!-- ANCHOR - Địa chỉ -->
                  <b-col
                    cols="12"
                    md="7"
                  >
                    <!-- <validation-provider
                      #default="validationContext"
                      name="Địa chỉ"
                      rules="required"
                    > -->
                    <b-form-group label="Địa chỉ (Url)">
                      <!-- :state="getValidationState(validationContext) === false ? false : null" -->
                      <b-form-input
                        :id="`url-${index}`"
                        v-model="item.url"
                        class="font-weight-bold"
                        placeholder="Nhập vào"
                      />
                    </b-form-group>
                    <!-- </validation-provider> -->
                  </b-col>

                  <!-- ANCHOR - Remove Button -->
                  <b-col
                    cols="12"
                    md="1"
                    class="mb-50 d-flex align-items-end justify-content-end"
                  >
                    <!-- :disabled="(dataToAdd.length === 1) && item.isNew" -->
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="flat-danger"
                      class="p-50"
                      style="margin-bottom: 7px;"
                      @click="handleDeleteItem(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        size="20"
                      />
                    </b-button>
                  </b-col>

                  <b-col
                    v-if="agencyDataToAdd.socialAccounts.length > 1"
                    cols="12"
                  >
                    <hr class="mt-0">
                  </b-col>
                </b-row>
              </div>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="flat-primary"
                class="py-50 px-2 rounded d-flex-center"
                :class="agencyDataToAdd.socialAccounts.length === 0 ? 'mt-1' : ''"
                @click="handleAddItem"
              >
                <feather-icon
                  icon="PlusIcon"
                  size="20"
                />
                <span class="ml-25">Thêm mạng xã hội</span>
              </b-button>
            </b-card-body>
          </b-card>
          <!-- !SECTION -->

          <!-- SECTION Action Buttons -->
          <div class="d-flex mt-1 justify-content-center">
            <!-- ANCHOR Back button -->
            <b-button
              variant="secondary"
              class="mr-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="backHandle"
            >
              {{ $t('back') }}
            </b-button>

            <!-- ANCHOR Clear button -->
            <b-button
              variant="danger"
              class="mx-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              :disabled="pristine"
              @click="clearHandle"
            >
              {{ $t('clear') }}
            </b-button>

            <!-- ANCHOR Next button -->
            <b-button
              variant="info"
              class="ml-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              :disabled="invalid"
              type="submit"
            >
              {{ $t('next') }}
            </b-button>
          </div>
          <!-- !SECTION -->
        </b-form>
        <!-- !SECTION -->
      </validation-observer>
    </section>
  </IAmOverlay>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BFormFile,
  BFormCheckbox,
  BImg,
  BFormDatepicker,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  ref, computed, watchEffect, onMounted,
} from '@vue/composition-api'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'

import VueI18n from '@/libs/i18n'
import store from '@/store'
import {
  hotAirlineOptions, statusAgencyOptions,
} from '@/constants/selectOptions'
import { getUserData } from '@/api/auth/utils'
import env from '@/libs/env'

import { avatarText, formatCurrency, sizeFormatter } from '@core/utils/filter'
import formValidation from '@core/comp-functions/forms/form-validation'
import { trimInput, upperCaseFormatter } from '@core/comp-functions/forms/formatter-input'
import { useInputImageFormData, uploadMultipleFile } from '@core/comp-functions/forms/form-utils'

import useAgenciesHandle, { shortenFileName } from '@agencies/useAgenciesHandle'

import useToast from '@useToast'
import {
  required, min, max, email, integer, isUnique, phoneNumber,
} from '@validations'

export default {
  components: {
    BForm,
    flatPickr,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BFormFile,
    BFormCheckbox,
    BImg,
    BFormDatepicker,
    vSelect,

    ValidationProvider,
    ValidationObserver,
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
  },
  props: {
    agencyData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { toastError } = useToast()
    const agencyFile = ref({
      idFrontImage: null,
      idBackImage: null,
      documentContract: null,
    })
    const locale = computed(() => VueI18n.locale).value

    const blankAgencyData = {
      creator: getUserData().employeeData.id, // required
      agencyName: null, // required
      agencyOwner: {
        name: null, // required
        phoneNumber: null,
      },
      status: 'SIGNED', // required, enums: [NEW, IN_CONTRACT, SIGNED, DELIVERED, DONE]
      enableSendMonthlyReport: true, // required, boolean
      agencyManager: null,
      warningCredit: 0,
      limitCredit: 0,
      paymentMethod: 'OVERDRAFT',
      // taxCode: null,
      // avatar: null,
      // emailAddress: null,
      // isActive: null, // boolean
      // bankCode: null,
      // bankName: null,
      // businessFieldAmount: null, // number
      // holderName: null,
      // identityCardNumber: null,
      // identityCardName: null,
      // placeOfIssue: null,
      airAccount: {},
      documentContractUrl: [],
      socialAccounts: [
        {
          url: null,
          type: null,
        },
      ],
    }

    const meData = computed(() => store.getters['userStore/getMeData'])
    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    const isRoleF2 = computed(() => store.getters['userStore/getRoleF2'])

    const agencyDataToAdd = ref(JSON.parse(JSON.stringify(blankAgencyData)))
    const resetAgencyData = () => {
      agencyDataToAdd.value = JSON.parse(JSON.stringify(blankAgencyData))
    }
    const { refFormObserver, getValidationState, resetForm } = formValidation(resetAgencyData)
    const {
      validateEmail,
      loading,
      agenciesManagerList,
      searchAgenciesManager,
      openAgenciesManager,
      MAX_SIZE_TO_UPLOAD_FILE,
      fileCanUpload,
    } = useAgenciesHandle()
    onMounted(() => {
      store.dispatch('globalConfig/getAvailableDistributorSource')
    })
    const distributorsList = computed(() => store.getters['globalConfig/getAllDistributors'])
    const issueLimit = ref([])
    const changeIssueLimit = (checked, airlineSource) => {
      issueLimit.value = issueLimit.value.map(issue => {
        if (issue.airlineSource === airlineSource) {
          issue.allowance = checked
        }
        return issue
      })
    }
    const bookingLimit = ref([])
    const changeBookingLimit = (checked, airlineSource) => {
      bookingLimit.value = bookingLimit.value.map(issue => {
        if (issue.airlineSource === airlineSource) {
          issue.enableBooking = checked
        }
        return issue
      })
    }

    const sourceDefaultCanNotIssue = computed(() => isRoleF1.value ? ['1G'] : ['1G', 'F1', 'AK', '1A', 'TH'])
    const sourceDefaultCanNotBook = computed(() => isRoleF2.value ? ['1G'] : [])
    watchEffect(() => {
      if (distributorsList.value) {
        const limitList = (field = 'enableBooking', type = 'book') => distributorsList.value.map(source => ({
          airlineSource: source,
          [field]: type === 'issue'
            ? !sourceDefaultCanNotIssue.value.includes(source)
            : type === 'book'
              ? !sourceDefaultCanNotBook.value.includes(source)
              : true,
        }))

        bookingLimit.value = limitList('enableBooking')
        issueLimit.value = limitList('allowance', 'issue')
      }
    })
    function submitHandle() {
      if (agencyDataToAdd.value.emailAddress === '') agencyDataToAdd.value.emailAddress = null
      if (agencyDataToAdd.value.agencyFoundingDate === '') agencyDataToAdd.value.agencyFoundingDate = null
      if (agencyDataToAdd.value.birthDayOwner === '') agencyDataToAdd.value.birthDayOwner = null

      if (agencyDataToAdd.value.agencyCode) agencyDataToAdd.value.agencyCode = agencyDataToAdd.value.agencyCode.toLowerCase()
      emit('update:agency-data', {
        ...agencyDataToAdd.value,
        warningCredit: Number(agencyDataToAdd.value.limitCredit),
        limitCredit: Number(agencyDataToAdd.value.limitCredit),
        issueLimit: issueLimit.value,
        bookingLimit: bookingLimit.value,
        socialAccounts: agencyDataToAdd.value.socialAccounts.filter(i => i.type && i.url),
      })
      emit('next-step')
    }

    function nextHandle() {
      if (!agencyDataToAdd.value.agencyCode) {
        const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: '<span>Bạn chưa cài đặt <em class=\'font-weight-bolder\'>Mã đại lý (Tên đăng nhập)</em>. Mã đại lý (Tên đăng nhập) sẽ được tự động tạo, Bạn có muốn tạo không ?</span>' } })
        this.$bvModal
          .msgBoxConfirm(titleMsg(), {
            title: 'XÁC NHẬN',
            size: 'sm',
            okVariant: 'success',
            okTitle: 'Tiếp tục tạo',
            cancelTitle: 'Huỷ',
            cancelVariant: 'outline-danger',
            hideHeaderClose: true,
            centered: true,
          })
          .then(value => {
            if (value) {
              submitHandle()
            }
          })
      } else {
        submitHandle()
      }
    }

    function backHandle() {
      this.$router.go(-1)
    }

    function clearHandle() {
      this.$bvModal
        .msgBoxConfirm(this.$t('modal.clearForm'), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) resetForm()
        })
    }

    // Upload file
    const refInputFrontIDPhoto = ref(null)
    const refInputBackIDPhoto = ref(null)
    const refInputDocumentContractUrl = ref(null)
    const previewIdentityCardFrontImage = ref()
    const previewIdentityCardBackImage = ref()
    const previewDocumentContract = ref()

    const { inputImageFormData: inputImageIDCardFrontFormData } = useInputImageFormData(refInputFrontIDPhoto, data => {
      agencyDataToAdd.value.identityCardFrontImage = data.url
      previewIdentityCardFrontImage.value = data.url
    })

    const { inputImageFormData: inputImageIDCardBackFormData } = useInputImageFormData(refInputBackIDPhoto, data => {
      agencyDataToAdd.value.identityCardBackImage = data.url
      previewIdentityCardBackImage.value = data.url
    })

    const childFloor = computed(() => isRoleF1.value ? 'F2' : isRoleF2.value ? 'F3' : '')
    // Quyền được xuất vé + quyền được đặt vé ở màn tạo Không cho sửa
    const canChangeIssueBookingLimit = false

    const limitF2 = ref(0)

    watchEffect(() => {
      if (isRoleF2.value) {
        limitF2.value = meData.value?.agency?.limitCredit
      }
      // if (isRoleF2.value) {
      //   apiAgencies.checkCredit(meData.value?.agency?.id, { amount: 12323 })
      //     .then(res => {
      //       limitF2.value = res.data //
      //     })
      // }
    })

    const selectInputText = event => {
      event.target.select()
    }

    function handleDeleteItem(index) {
      agencyDataToAdd.value.socialAccounts.splice(index, 1)
    }

    function handleAddItem() {
      agencyDataToAdd.value.socialAccounts.push({
        url: null,
        type: null,
      })
    }

    const loadingUpload = ref(false)
    async function handleUploadContractFiles() {
      loadingUpload.value = true
      try {
        const files = refInputDocumentContractUrl.value.files

        const uploadedFile = await uploadMultipleFile(files)
        agencyDataToAdd.value.documentContractUrl = [...agencyDataToAdd.value.documentContractUrl, ...uploadedFile.map(i => i.url)]
      } catch (error) {
        toastError(error.message)
      } finally {
        loadingUpload.value = false
      }
    }

    function deleteDocumentContract(docIndex) {
      agencyDataToAdd.value.documentContractUrl.splice(docIndex, 1)
    }

    return {
      handleDeleteItem,
      handleAddItem,
      flatPickr,
      locale,
      Vietnamese,
      agencyDataToAdd,
      refFormObserver,
      getValidationState,
      avatarText,
      agencyFile,
      selectInputText,
      limitF2,

      // select options
      hotAirlineOptions,
      statusAgencyOptions,

      // Validations
      required,
      min,
      max,
      email,
      integer,
      isUnique,
      phoneNumber,

      // Methods
      backHandle,
      nextHandle,
      clearHandle,
      validateEmail,
      trimInput,
      upperCaseFormatter,
      formatCurrency,

      canChangeIssueBookingLimit,
      changeIssueLimit,
      issueLimit,
      changeBookingLimit,
      bookingLimit,

      // Upload file
      refInputFrontIDPhoto,
      previewIdentityCardFrontImage,
      refInputBackIDPhoto,
      previewIdentityCardBackImage,
      refInputDocumentContractUrl,
      previewDocumentContract,

      // Filter Agency Manager
      loading,
      agenciesManagerList,
      searchAgenciesManager,
      openAgenciesManager,
      inputImageIDCardFrontFormData,
      inputImageIDCardBackFormData,
      handleUploadContractFiles,

      isRoleF1,
      isRoleF2,
      childFloor,
      shortenFileName,
      deleteDocumentContract,
      MAX_SIZE_TO_UPLOAD_FILE,
      sizeFormatter,
      fileCanUpload,
      loadingUpload,
      isLimitF3BalanceAccordingF2Balance: env.isLimitF3BalanceAccordingF2Balance,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
// input[type="file"] {
//   /* Input Style */
//   position: relative;
//   padding: 0.5rem;
//   color: var(--primary);
//   background-color: transparent;
//   border-radius: 0.3125rem;
//   cursor: pointer;
//   width: fit-content;
//   border: 1px solid transparent;

//   &:hover {
//     border: 1px solid var(--primary);
//   }

//   /* Input Button */
//   &::file-selector-button {
//     padding: 0.625rem 0.9375rem 0.625rem 2.125rem;
//     color: #fff;
//     font-size: 0.9rem;
//     background-color: var(--primary);
//     border: none;
//     border-radius: 0.25rem;
//     cursor: pointer;
//     transition: background-color 0.3s ease-in;
//   }

//   /* Button Icon */
//   &::before {
//     position: absolute;
//     top: 50%;
//     left: 1.3625rem;
//     width: 0.9375rem;
//     height: 1.25rem;
//     transform: translateY(-50%);
//     pointer-events: none;
//     content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3E%3Cpath d='M448 416V352C448 334.326 433.672 320 416 320S384 334.326 384 352V416C384 433.674 369.672 448 352 448H96C78.328 448 64 433.674 64 416V352C64 334.326 49.672 320 32 320S0 334.326 0 352V416C0 469.02 42.98 512 96 512H352C405.02 512 448 469.02 448 416ZM246.625 342.625L374.625 214.625C387.133 202.117 387.117 181.867 374.625 169.375C362.125 156.875 341.875 156.875 329.375 169.375L256 242.75V32C256 14.312 241.688 0 224 0S192 14.312 192 32V242.75L118.625 169.375C106.125 156.875 85.875 156.875 73.375 169.375S60.875 202.125 73.375 214.625L201.375 342.625C213.875 355.125 234.125 355.125 246.625 342.625Z' fill='%23fff' /%3E%3C/svg%3E");
//   }
// }

.custom-file-button {
  input[type="file"] {
    margin-left: -2px !important;

    &::-webkit-file-upload-button {
      display: none;
    }
    &::file-selector-button {
      display: none;
    }
  }

  &:hover {
    label {
      background-color: #dde0e3;
      cursor: pointer;
    }
  }
}
</style>
