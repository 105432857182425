var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading || _vm.loadingUpload
    }
  }, [_c('section', [_c('validation-observer', {
    ref: "refFormObserver",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var handleSubmit = _ref.handleSubmit,
          invalid = _ref.invalid,
          pristine = _ref.pristine;
        return [_c('b-form', {
          on: {
            "submit": function submit($event) {
              $event.preventDefault();
              return handleSubmit(_vm.nextHandle);
            }
          }
        }, [_c('b-card', {
          staticClass: "border mt-1",
          attrs: {
            "header-tag": "header",
            "border-variant": "info",
            "header-bg-variant": "light-info",
            "header-class": "py-1"
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('h6', {
                staticClass: "m-0"
              }, [_vm._v(" " + _vm._s(_vm.$t('agency.agencyInfo')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-card-body', {
          staticClass: "p-0"
        }, [_c('b-row', {
          staticClass: "mt-1"
        }, [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Agency Owner",
            "rules": "required|max:150"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "agencyOwner"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.labelOwner')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "agencyOwner",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "maxlength": "150",
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('agency.placeholderOwner')
                },
                model: {
                  value: _vm.agencyDataToAdd.agencyOwner.name,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyDataToAdd.agencyOwner, "name", $$v);
                  },
                  expression: "agencyDataToAdd.agencyOwner.name"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('agency.labelEmail'),
            "rules": "required|email|max:150"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "emailAddress"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.labelEmail')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "emailAddress",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "maxlength": "150",
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('agency.placeholderEmail'),
                  "value": _vm.agencyDataToAdd.emailAddress,
                  "debounce": "300"
                },
                model: {
                  value: _vm.agencyDataToAdd.emailAddress,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyDataToAdd, "emailAddress", $$v);
                  },
                  expression: "agencyDataToAdd.emailAddress"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('agency.labelOwnerPhone'),
            "rules": "required|phoneNumber"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "ownerPhone"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.labelOwnerPhone')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "ownerPhone",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput,
                  "debounce": 300,
                  "placeholder": _vm.$t('agency.placeholderOwnerPhone')
                },
                model: {
                  value: _vm.agencyDataToAdd.agencyOwner.phoneNumber,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyDataToAdd.agencyOwner, "phoneNumber", $$v);
                  },
                  expression: "agencyDataToAdd.agencyOwner.phoneNumber"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('agency.birthday'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "birthday"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.birthday')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('flat-pickr', {
                staticClass: "d-none",
                model: {
                  value: _vm.agencyDataToAdd.birthDayOwner,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyDataToAdd, "birthDayOwner", $$v);
                  },
                  expression: "agencyDataToAdd.birthDayOwner"
                }
              }), _c('flat-pickr', {
                staticClass: "form-control",
                attrs: {
                  "id": "birthDay",
                  "name": "birthday",
                  "placeholder": _vm.$t('customer.placeholderDoB'),
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "config": {
                    allowInput: true,
                    locale: _vm.locale === 'vi' ? _vm.Vietnamese : null,
                    dateFormat: 'Y-m-d',
                    altFormat: 'd-m-Y',
                    altInput: true,
                    disableMobile: true,
                    maxDate: new Date(),
                    minDate: new Date(1920, 0, 1)
                  }
                },
                model: {
                  value: _vm.agencyDataToAdd.birthDayOwner,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyDataToAdd, "birthDayOwner", $$v);
                  },
                  expression: "agencyDataToAdd.birthDayOwner"
                }
              }), _c('feather-icon', {
                staticStyle: {
                  "position": "absolute",
                  "top": "55%",
                  "right": "20px",
                  "transform": "translateY(-50%)",
                  "cursor": "pointer",
                  "color": "#aaa"
                },
                attrs: {
                  "icon": "CalendarIcon",
                  "size": "20"
                }
              })], 1), _c('b-form-invalid-feedback', {
                attrs: {
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                }
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('agency.labelIdentityCardNum'),
            "rules": "max:15"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "identityCardNumber"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.labelIdentityCardNum')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                directives: [{
                  name: "remove-non-numeric-chars",
                  rawName: "v-remove-non-numeric-chars.allNumber",
                  modifiers: {
                    "allNumber": true
                  }
                }],
                attrs: {
                  "id": "identityCardNumber",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('agency.placeholderIdentityCardNum')
                },
                model: {
                  value: _vm.agencyDataToAdd.identityCardNumber,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyDataToAdd, "identityCardNumber", $$v);
                  },
                  expression: "agencyDataToAdd.identityCardNumber"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('agency.labelIdentityCardName'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "identityCardName"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.labelIdentityCardName')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "identityCardName",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput,
                  "maxlength": "255",
                  "placeholder": _vm.$t('agency.placeholderIdentityCardName')
                },
                model: {
                  value: _vm.agencyDataToAdd.identityCardName,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyDataToAdd, "identityCardName", $$v);
                  },
                  expression: "agencyDataToAdd.identityCardName"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('agency.labelPlaceOfIssue'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "placeOfIssue"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.labelPlaceOfIssue')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "placeOfIssue",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput,
                  "maxlength": "255",
                  "placeholder": _vm.$t('agency.placeholderPlaceOfIssue')
                },
                model: {
                  value: _vm.agencyDataToAdd.placeOfIssue,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyDataToAdd, "placeOfIssue", $$v);
                  },
                  expression: "agencyDataToAdd.placeOfIssue"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12"
          }
        }, [_c('b-row', [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          ref: "refProviderIDFrontImageFile",
          attrs: {
            "name": _vm.$t('agency.uploadFrontId')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                staticClass: "font-weight-bold",
                attrs: {
                  "label-for": "frontIdFrontImage"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.uploadFrontId')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('div', {
                staticClass: "input-group custom-file-button"
              }, [_c('label', {
                staticClass: "input-group-text",
                attrs: {
                  "for": "frontIdFrontImage"
                }
              }, [_vm._v(" " + _vm._s(_vm.$t('agency.uploadImage')))]), _c('b-form-file', {
                ref: "refInputFrontIDPhoto",
                staticClass: "form-control",
                attrs: {
                  "id": "frontIdFrontImage",
                  "accept": ".jpg, .png",
                  "plain": "",
                  "placeholder": "Chưa chọn ảnh",
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                on: {
                  "input": _vm.inputImageIDCardFrontFormData
                },
                model: {
                  value: _vm.agencyFile.idFrontImage,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyFile, "idFrontImage", $$v);
                  },
                  expression: "agencyFile.idFrontImage"
                }
              })], 1), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          ref: "refProviderIDBackImageFile",
          attrs: {
            "name": _vm.$t('agency.uploadBackId')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                staticClass: "font-weight-bold",
                attrs: {
                  "label-for": "frontIdBackImage"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.uploadBackId')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('div', {
                staticClass: "input-group custom-file-button"
              }, [_c('label', {
                staticClass: "input-group-text",
                attrs: {
                  "for": "frontIdBackImage"
                }
              }, [_vm._v(" " + _vm._s(_vm.$t('agency.uploadImage')))]), _c('b-form-file', {
                ref: "refInputBackIDPhoto",
                staticClass: "form-control",
                attrs: {
                  "id": "frontIdBackImage",
                  "accept": ".jpg, .png",
                  "plain": "",
                  "placeholder": "Chưa chọn ảnh",
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                on: {
                  "input": _vm.inputImageIDCardBackFormData
                },
                model: {
                  value: _vm.agencyFile.idBackImage,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyFile, "idBackImage", $$v);
                  },
                  expression: "agencyFile.idBackImage"
                }
              })], 1), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "5",
            "md": "6",
            "centered": ""
          }
        }, [_vm.agencyDataToAdd.identityCardFrontImage || _vm.previewIdentityCardFrontImage ? _c('b-img', {
          staticClass: "border shadow-lg mb-1",
          staticStyle: {
            "max-height": "200px"
          },
          attrs: {
            "src": _vm.agencyDataToAdd.identityCardFrontImage || _vm.previewIdentityCardFrontImage,
            "fluid": "",
            "alt": "id card"
          }
        }) : _vm._e()], 1), _c('b-col', {
          attrs: {
            "cols": "5",
            "md": "6",
            "centered": ""
          }
        }, [_vm.agencyDataToAdd.identityCardBackImage || _vm.previewIdentityCardBackImage ? _c('b-img', {
          staticClass: "border shadow-lg mb-1",
          staticStyle: {
            "max-height": "200px"
          },
          attrs: {
            "src": _vm.agencyDataToAdd.identityCardBackImage || _vm.previewIdentityCardBackImage,
            "fluid": "",
            "alt": "id card"
          }
        }) : _vm._e()], 1)], 1)], 1)], 1)], 1)], 1), _c('b-card', {
          staticClass: "border mt-1",
          attrs: {
            "header-tag": "header",
            "border-variant": "info",
            "header-bg-variant": "light-info",
            "header-class": "py-1"
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('h6', {
                staticClass: "m-0"
              }, [_vm._v(" " + _vm._s(_vm.$t('agency.agencyBusinessInfo')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-card-body', {
          staticClass: "p-0"
        }, [_c('b-row', {
          staticClass: "mt-1"
        }, [_c('b-col', {
          attrs: {
            "cols": "12",
            "sm": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('agency.agencyCompanyName'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "agency-name"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.agencyCompanyName')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "agency-name",
                  "name": _vm.$t('agency.agencyCompanyName'),
                  "placeholder": "".concat(_vm.$t('agency.enter'), " ").concat(_vm.$t('agency.agencyCompanyName')),
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                model: {
                  value: _vm.agencyDataToAdd.agencyName,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyDataToAdd, "agencyName", $$v);
                  },
                  expression: "agencyDataToAdd.agencyName"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Tax Code",
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "tax-code"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.labelTaxCode')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                directives: [{
                  name: "remove-non-numeric-chars",
                  rawName: "v-remove-non-numeric-chars.allNumber",
                  modifiers: {
                    "allNumber": true
                  }
                }],
                attrs: {
                  "id": "tax-code",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "lazy-formatter": "",
                  "formatter": _vm.trimInput,
                  "placeholder": _vm.$t('agency.placeholderTaxCode')
                },
                model: {
                  value: _vm.agencyDataToAdd.taxCode,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyDataToAdd, "taxCode", $$v);
                  },
                  expression: "agencyDataToAdd.taxCode"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('agency.labelMonthlyReport'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "enableSendMonthlyReport",
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.labelMonthlyReport')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('v-select', {
                attrs: {
                  "options": [{
                    label: 'Yes',
                    value: true
                  }, {
                    label: 'No',
                    value: false
                  }],
                  "reduce": function reduce(val) {
                    return val.value;
                  },
                  "clearable": false,
                  "input-id": "enableSendMonthlyReport",
                  "placeholder": _vm.$t('agency.placeholderMonthlyReport')
                },
                scopedSlots: _vm._u([{
                  key: "option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")])];
                  }
                }, {
                  key: "selected-option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")])];
                  }
                }, {
                  key: "no-options",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.agencyDataToAdd.enableSendMonthlyReport,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyDataToAdd, "enableSendMonthlyReport", $$v);
                  },
                  expression: "agencyDataToAdd.enableSendMonthlyReport"
                }
              }), _c('b-form-invalid-feedback', {
                attrs: {
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                }
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('agency.agencyCode'),
            "rules": "min:".concat(_vm.isRoleF1 ? '2' : '4', "|userName|isUnique:agencies,agencyCode")
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "agencyCode"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.agencyCode')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "agencyCode",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "maxlength": "16",
                  "disabled": _vm.isRoleF2,
                  "debounce": 300,
                  "formatter": _vm.upperCaseFormatter,
                  "placeholder": "".concat(_vm.$t('agency.enter'), " ").concat(_vm.$t('agency.agencyCode'))
                },
                model: {
                  value: _vm.agencyDataToAdd.agencyCode,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyDataToAdd, "agencyCode", $$v);
                  },
                  expression: "agencyDataToAdd.agencyCode"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "sm": "6"
          }
        }, [_c('b-form-group', {
          attrs: {
            "label-for": "agency-address",
            "label": _vm.$t('myAccount.addressCompany')
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "agency-address",
            "name": _vm.$t('myAccount.addressCompany'),
            "placeholder": "".concat(_vm.$t('myAccount.enter'), " ").concat(_vm.$t('myAccount.addressCompany'))
          },
          model: {
            value: _vm.agencyDataToAdd.agencyAddress,
            callback: function callback($$v) {
              _vm.$set(_vm.agencyDataToAdd, "agencyAddress", $$v);
            },
            expression: "agencyDataToAdd.agencyAddress"
          }
        })], 1)], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('agency.agencyFoundingDate')
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label": "".concat(_vm.$t('agency.agencyFoundingDate')),
                  "label-for": "agencyFoundingDate"
                }
              }, [_c('flat-pickr', {
                staticClass: "d-none",
                model: {
                  value: _vm.agencyDataToAdd.agencyFoundingDate,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyDataToAdd, "agencyFoundingDate", $$v);
                  },
                  expression: "agencyDataToAdd.agencyFoundingDate"
                }
              }), _c('flat-pickr', {
                staticClass: "form-control",
                attrs: {
                  "placeholder": _vm.$t('agency.agencyFoundingDate'),
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "config": {
                    allowInput: true,
                    locale: _vm.locale === 'vi' ? _vm.Vietnamese : null,
                    dateFormat: 'Y-m-d',
                    altFormat: 'd-m-Y',
                    altInput: true,
                    disableMobile: true,
                    minDate: '1920-01-01',
                    maxDate: new Date()
                  }
                },
                model: {
                  value: _vm.agencyDataToAdd.agencyFoundingDate,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyDataToAdd, "agencyFoundingDate", $$v);
                  },
                  expression: "agencyDataToAdd.agencyFoundingDate"
                }
              }), _c('feather-icon', {
                staticStyle: {
                  "position": "absolute",
                  "top": "55%",
                  "right": "20px",
                  "transform": "translateY(-50%)",
                  "cursor": "pointer",
                  "color": "#aaa"
                },
                attrs: {
                  "icon": "CalendarIcon",
                  "size": "20"
                }
              })], 1), _c('b-form-invalid-feedback', {
                attrs: {
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                }
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "sm": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": "Agency Limit Credit",
            "rules": _vm.isRoleF2 && _vm.isLimitF3BalanceAccordingF2Balance ? "integer|agencyLimitCredit:".concat(_vm.agencyDataToAdd.warningCredit, "|agencyMaximumCredit:").concat(_vm.limitF2) : "integer|agencyLimitCredit:".concat(_vm.agencyDataToAdd.warningCredit)
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "agency-limit-credit"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_c('div', [_c('span', [_vm._v(_vm._s(_vm.$t('agency.labelLimitCredit')))]), _vm.isRoleF2 && _vm.isLimitF3BalanceAccordingF2Balance ? _c('p', {
                      staticClass: "d-inline mb-0"
                    }, [_vm._v(" ("), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("Tối đa: " + _vm._s("< ".concat(_vm.formatCurrency(_vm.limitF2))))]), _vm._v(") ")]) : _vm._e()])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-form-input', {
                attrs: {
                  "id": "agency-limit-credit",
                  "state": _vm.getValidationState(validationContext) === false ? false : null,
                  "type": "number",
                  "placeholder": "".concat(_vm.$t('myAccount.enter'), " ").concat(_vm.$t('agency.labelLimitCredit'))
                },
                on: {
                  "click": _vm.selectInputText
                },
                model: {
                  value: _vm.agencyDataToAdd.limitCredit,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyDataToAdd, "limitCredit", $$v);
                  },
                  expression: "agencyDataToAdd.limitCredit"
                }
              }), _c('b-form-invalid-feedback', {
                attrs: {
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                }
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('agency.paymentMethod'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "paymentMethod",
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.paymentMethod')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('v-select', {
                attrs: {
                  "options": [{
                    label: 'overdraft',
                    value: 'OVERDRAFT'
                  }, {
                    label: 'prepay',
                    value: 'PREPAY'
                  }],
                  "reduce": function reduce(val) {
                    return val.value;
                  },
                  "clearable": false,
                  "input-id": "paymentMethod",
                  "placeholder": _vm.$t('agency.paymentMethod')
                },
                scopedSlots: _vm._u([{
                  key: "option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$t("agency.".concat(data.label))) + " ")])];
                  }
                }, {
                  key: "selected-option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$t("agency.".concat(data.label))) + " ")])];
                  }
                }, {
                  key: "no-options",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.agencyDataToAdd.paymentMethod,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyDataToAdd, "paymentMethod", $$v);
                  },
                  expression: "agencyDataToAdd.paymentMethod"
                }
              }), _c('b-form-invalid-feedback', {
                attrs: {
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                }
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          ref: "documentContractFile",
          attrs: {
            "name": "".concat(_vm.$t('agency.documentContract'))
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                staticClass: "font-weight-bold",
                attrs: {
                  "label": _vm.$t('agency.documentContract'),
                  "label-for": "documentContractUrlFile"
                }
              }, [_vm.agencyDataToAdd.documentContractUrl.length ? _c('div', _vm._l(_vm.agencyDataToAdd.documentContractUrl, function (docContractFile, docContractFileIndex) {
                return _c('div', {
                  key: docContractFileIndex
                }, [_c('em', [_vm._v(_vm._s(docContractFileIndex + 1) + ".")]), _c('a', {
                  staticClass: "hover-underline",
                  attrs: {
                    "href": docContractFile,
                    "alt": "download file",
                    "target": "_blank"
                  }
                }, [_vm._v(" " + _vm._s(_vm.shortenFileName(docContractFile)) + " ")]), _c('b-button', {
                  staticClass: "p-50 rounded-circle",
                  attrs: {
                    "variant": "flat-danger"
                  },
                  on: {
                    "click": function click($event) {
                      return _vm.deleteDocumentContract(docContractFileIndex);
                    }
                  }
                }, [_c('IAmIcon', {
                  attrs: {
                    "icon": "closeOutline"
                  }
                })], 1)], 1);
              }), 0) : _c('span', {
                staticClass: "text-secondary"
              }, [_vm._v(" Chưa có file ")]), _c('div', {
                staticClass: "mt-25"
              }, [_c('div', {
                staticClass: "input-group custom-file-button"
              }, [_c('label', {
                staticClass: "input-group-text",
                attrs: {
                  "for": "documentContractUrlFile"
                }
              }, [_vm._v(" " + _vm._s(_vm.$t('agency.uploadContract')))]), _c('b-form-file', {
                ref: "refInputDocumentContractUrl",
                staticClass: "form-control",
                attrs: {
                  "id": "documentContractUrlFile",
                  "accept": _vm.fileCanUpload,
                  "plain": "",
                  "multiple": "",
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                on: {
                  "input": _vm.handleUploadContractFiles
                },
                model: {
                  value: _vm.agencyFile.documentContract,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyFile, "documentContract", $$v);
                  },
                  expression: "agencyFile.documentContract"
                }
              })], 1), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1), _c('small', {
                staticClass: "font-italic text-secondary"
              }, [_vm._v("Hệ thống chỉ hỗ trợ tải file " + _vm._s(_vm.fileCanUpload) + " có dung lượng nhỏ hơn " + _vm._s(_vm.sizeFormatter(_vm.MAX_SIZE_TO_UPLOAD_FILE)))])])];
            }
          }], null, true)
        })], 1)], 1)], 1)], 1), _c('b-card', {
          staticClass: "border my-1",
          attrs: {
            "header-tag": "header",
            "border-variant": "info",
            "header-bg-variant": "light-info",
            "header-class": "py-1"
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('h6', {
                staticClass: "m-0"
              }, [_vm._v(" " + _vm._s(_vm.$t('agency.labelStatus')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-card-body', {
          staticClass: "p-0"
        }, [_c('b-row', {
          staticClass: "mt-1"
        }, [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('agency.labelStatus'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "status",
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.labelStatus')) + " "), _c('span', {
                      staticClass: "text-danger"
                    }, [_vm._v("(*)")])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('v-select', {
                attrs: {
                  "options": _vm.statusAgencyOptions,
                  "reduce": function reduce(val) {
                    return val.value;
                  },
                  "clearable": false,
                  "input-id": "status",
                  "placeholder": _vm.$t('agency.placeholderStatus')
                },
                scopedSlots: _vm._u([{
                  key: "option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")])];
                  }
                }, {
                  key: "selected-option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")])];
                  }
                }, {
                  key: "no-options",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.agencyDataToAdd.status,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyDataToAdd, "status", $$v);
                  },
                  expression: "agencyDataToAdd.status"
                }
              }), _c('b-form-invalid-feedback', {
                attrs: {
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                }
              }, [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1)], 1)], 1)], 1), _c('b-card', {
          staticClass: "border my-1",
          attrs: {
            "header-tag": "header",
            "border-variant": "info",
            "header-bg-variant": "light-info",
            "header-class": "py-1"
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('h6', {
                staticClass: "m-0"
              }, [_vm._v(" " + _vm._s(_vm.$t('agency.airAccountForAgencyText')) + " ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-card-body', {
          staticClass: "p-0"
        }, [_c('h5', {
          staticClass: "my-2"
        }, [_vm._v(" " + _vm._s(_vm.$t('agency.provideAccount')) + " ")]), _c('b-row', {
          staticClass: "mt-1"
        }, [_c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('agency.labelAirUsername'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "airAccountUsername"
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.labelAirUsername')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('v-select', {
                attrs: {
                  "id": "airAccountUsername",
                  "options": [],
                  "reduce": function reduce(val) {
                    return val.value;
                  },
                  "clearable": "",
                  "input-id": "airAccountUsername",
                  "placeholder": _vm.$t('agency.placeholderAirUsername')
                },
                scopedSlots: _vm._u([{
                  key: "option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")])];
                  }
                }, {
                  key: "selected-option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")])];
                  }
                }, {
                  key: "no-options",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.agencyDataToAdd.airAccount.username,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyDataToAdd.airAccount, "username", $$v);
                  },
                  expression: "agencyDataToAdd.airAccount.username"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1), _c('b-col', {
          attrs: {
            "cols": "12",
            "md": "6"
          }
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('agency.labelAirline'),
            "rules": ""
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(validationContext) {
              return [_c('b-form-group', {
                attrs: {
                  "label-for": "airline",
                  "state": _vm.getValidationState(validationContext) === false ? false : null
                },
                scopedSlots: _vm._u([{
                  key: "label",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('agency.labelAirline')) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('v-select', {
                attrs: {
                  "options": _vm.hotAirlineOptions,
                  "reduce": function reduce(val) {
                    return val.value;
                  },
                  "clearable": false,
                  "input-id": "airline",
                  "placeholder": _vm.$t('agency.placeholderAirline')
                },
                scopedSlots: _vm._u([{
                  key: "option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")])];
                  }
                }, {
                  key: "selected-option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")])];
                  }
                }, {
                  key: "no-options",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: _vm.agencyDataToAdd.airline,
                  callback: function callback($$v) {
                    _vm.$set(_vm.agencyDataToAdd, "airline", $$v);
                  },
                  expression: "agencyDataToAdd.airline"
                }
              }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
            }
          }], null, true)
        })], 1)], 1)], 1)], 1), _c('b-card', {
          staticClass: "border my-1",
          attrs: {
            "header-tag": "header",
            "border-variant": "info",
            "header-bg-variant": "light-info",
            "header-class": "py-1"
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('h6', {
                staticClass: "m-0"
              }, [_vm._v(" Cài đặt ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-card-body', {
          staticClass: "p-0"
        }, [_c('b-row', {
          staticClass: "mt-1"
        }, [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": 'Quyền được đặt vé'
          }
        }, _vm._l(_vm.bookingLimit, function (bLimit) {
          return _c('b-form-checkbox', {
            key: bLimit.airlineSource,
            staticClass: "custom-control-success",
            attrs: {
              "name": "check-button",
              "inline": "",
              "disabled": !_vm.canChangeIssueBookingLimit,
              "checked": bLimit.enableBooking
            },
            on: {
              "change": function change(checked) {
                return _vm.changeBookingLimit(checked, bLimit.airlineSource);
              }
            }
          }, [_vm._v(" " + _vm._s(_vm.$te("flight.sourceName.".concat(bLimit === null || bLimit === void 0 ? void 0 : bLimit.airlineSource.toUpperCase())) ? _vm.$t("flight.sourceName.".concat(bLimit === null || bLimit === void 0 ? void 0 : bLimit.airlineSource.toUpperCase())) : bLimit === null || bLimit === void 0 ? void 0 : bLimit.airlineSource.toUpperCase()) + " ")]);
        }), 1)], 1)], 1), _c('b-row', {
          staticClass: "mt-1"
        }, [_c('b-col', [_c('b-form-group', {
          attrs: {
            "label": 'Quyền được xuất vé'
          }
        }, [_vm.canChangeIssueBookingLimit ? _c('h6', {
          staticClass: "text-warning"
        }, [_vm._v(" Bạn đang chọn cho " + _vm._s(_vm.childFloor) + " tự xuất vé ")]) : _vm._e(), _vm._l(_vm.issueLimit, function (iLimit) {
          return _c('b-form-checkbox', {
            key: iLimit.airlineSource,
            staticClass: "custom-control-success",
            attrs: {
              "name": "check-button",
              "inline": "",
              "disabled": !_vm.canChangeIssueBookingLimit,
              "checked": iLimit.allowance
            },
            on: {
              "change": function change(checked) {
                return _vm.changeIssueLimit(checked, iLimit.airlineSource);
              }
            }
          }, [_vm._v(" " + _vm._s(_vm.$te("flight.sourceName.".concat(iLimit === null || iLimit === void 0 ? void 0 : iLimit.airlineSource.toUpperCase())) ? _vm.$t("flight.sourceName.".concat(iLimit === null || iLimit === void 0 ? void 0 : iLimit.airlineSource.toUpperCase())) : iLimit === null || iLimit === void 0 ? void 0 : iLimit.airlineSource.toUpperCase()) + " ")]);
        })], 2)], 1)], 1)], 1)], 1), _c('b-card', {
          staticClass: "border my-1",
          attrs: {
            "header-tag": "header",
            "border-variant": "info",
            "header-bg-variant": "light-info",
            "header-class": "py-1",
            "body-class": "pb-1 pt-50"
          },
          scopedSlots: _vm._u([{
            key: "header",
            fn: function fn() {
              return [_c('h6', {
                staticClass: "m-0"
              }, [_vm._v(" Mạng xã hội ")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-card-body', {
          staticClass: "p-0"
        }, [_vm.agencyDataToAdd.socialAccounts.length > 0 ? _c('div', _vm._l(_vm.agencyDataToAdd.socialAccounts, function (item, index) {
          return _c('b-row', {
            key: index,
            staticClass: "mx-0",
            attrs: {
              "no-gutters": ""
            }
          }, [_c('b-col', {
            staticClass: "pr-md-1",
            attrs: {
              "cols": "12",
              "md": "4"
            }
          }, [_c('b-form-group', {
            attrs: {
              "label": "M\u1EA1ng x\xE3 h\u1ED9i #".concat(index + 1)
            }
          }, [_c('b-form-input', {
            staticClass: "font-weight-bold",
            attrs: {
              "id": "number-".concat(index),
              "placeholder": "Nhập vào"
            },
            model: {
              value: item.type,
              callback: function callback($$v) {
                _vm.$set(item, "type", $$v);
              },
              expression: "item.type"
            }
          })], 1)], 1), _c('b-col', {
            attrs: {
              "cols": "12",
              "md": "7"
            }
          }, [_c('b-form-group', {
            attrs: {
              "label": "Địa chỉ (Url)"
            }
          }, [_c('b-form-input', {
            staticClass: "font-weight-bold",
            attrs: {
              "id": "url-".concat(index),
              "placeholder": "Nhập vào"
            },
            model: {
              value: item.url,
              callback: function callback($$v) {
                _vm.$set(item, "url", $$v);
              },
              expression: "item.url"
            }
          })], 1)], 1), _c('b-col', {
            staticClass: "mb-50 d-flex align-items-end justify-content-end",
            attrs: {
              "cols": "12",
              "md": "1"
            }
          }, [_c('b-button', {
            directives: [{
              name: "ripple",
              rawName: "v-ripple.400",
              value: 'rgba(234, 84, 85, 0.15)',
              expression: "'rgba(234, 84, 85, 0.15)'",
              modifiers: {
                "400": true
              }
            }],
            staticClass: "p-50",
            staticStyle: {
              "margin-bottom": "7px"
            },
            attrs: {
              "variant": "flat-danger"
            },
            on: {
              "click": function click($event) {
                return _vm.handleDeleteItem(index);
              }
            }
          }, [_c('feather-icon', {
            attrs: {
              "icon": "XIcon",
              "size": "20"
            }
          })], 1)], 1), _vm.agencyDataToAdd.socialAccounts.length > 1 ? _c('b-col', {
            attrs: {
              "cols": "12"
            }
          }, [_c('hr', {
            staticClass: "mt-0"
          })]) : _vm._e()], 1);
        }), 1) : _vm._e(), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "py-50 px-2 rounded d-flex-center",
          class: _vm.agencyDataToAdd.socialAccounts.length === 0 ? 'mt-1' : '',
          attrs: {
            "variant": "flat-primary"
          },
          on: {
            "click": _vm.handleAddItem
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "PlusIcon",
            "size": "20"
          }
        }), _c('span', {
          staticClass: "ml-25"
        }, [_vm._v("Thêm mạng xã hội")])], 1)], 1)], 1), _c('div', {
          staticClass: "d-flex mt-1 justify-content-center"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "variant": "secondary",
            "block": _vm.$store.getters['app/currentBreakPoint'] === 'xs'
          },
          on: {
            "click": _vm.backHandle
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('back')) + " ")]), _c('b-button', {
          staticClass: "mx-1",
          attrs: {
            "variant": "danger",
            "block": _vm.$store.getters['app/currentBreakPoint'] === 'xs',
            "disabled": pristine
          },
          on: {
            "click": _vm.clearHandle
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('clear')) + " ")]), _c('b-button', {
          staticClass: "ml-1",
          attrs: {
            "variant": "info",
            "block": _vm.$store.getters['app/currentBreakPoint'] === 'xs',
            "disabled": invalid,
            "type": "submit"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('next')) + " ")])], 1)], 1)];
      }
    }])
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }